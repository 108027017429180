import React, { Component } from "react";
import "./bio.css";
import image from "../../assets/coming-soon1.png";

import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdbreact";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class Bio extends Component {
  state = {
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  render() {
    return (
      <MDBRow>
        <MDBCol id="bio" md="12">
          <MDBCard>
            <MDBCardImage
              top
              src={image}
              overlay="white-slight"
              hover
              waves
              alt="MDBCard image cap"
            />
            <MDBCardBody>
              <MDBCardTitle>BIO</MDBCardTitle>
              <hr />
              <MDBCardText>
                At just 24 years old, Hattiesburg, MS native JT Glistenin has
                managed to find his voice...
              </MDBCardText>
              <a href="#!" className="black-text d-flex justify-content-end">
                <MDBBtn color="primary" onClick={this.toggle(4)}>
                  Read More
                </MDBBtn>
              </a>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBModal isOpen={this.state.modal4} toggle={this.toggle(4)} size="lg">
          <MDBModalHeader toggle={this.toggle(4)}>JT Glistenin</MDBModalHeader>
          <MDBModalBody>
            At just 24 years old, Hattiesburg, MS native JT Glistenin has
            managed to find his voice. Growing up bicoastly in Mississippi and
            Maryland, Glistenin discovered his love for the limelight via
            football and realized at just 10 years old what his future would
            look like. After some unexpected legal trouble, JT found himself
            back in Mississippi for good and once he heard T-Pain’s “Bartender”,
            he knew there was something to aspire to be. It was a wrap from
            there! For over 12 years, he hustled throughout life and the 24 year
            old has been shaping and molding his sound and continues to find his
            pocket. Heavily inspired by the likes of Boosie, Gucci, Young Dolph,
            and southern trap music as a whole, JT can be heard on his symphonic
            2019 track, “YRN” giving his contribution to the genre. When writing
            his music, JT Glistenin describes his writing process as coming
            prepared and ready to deliver while leaving room for the freedom and
            necessity to freestyle. Glistenin has gained wisdom in knowing that
            it’s not about rapping for the sake of it, but choosing to rap is
            deciding to show a reflection of one’s truest self. JT Glistenin is
            constantly evolving and can be heard on his single, “Workin’ My
            Move” with Authentic Empire OUT NOW!
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.toggle(4)}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBRow>
    );
  }
}

export default Bio;
