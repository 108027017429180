import React from "react";
import HoverPic from "./components/header/header";
import Listen from "./components/listen/listen";
// import Watch from "./components/watch/watchvideo";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";
import ContactPage from "./components/contact/contact";
import Gallery from "./components/gallery/gallery";



import "./App.css";

function App() {
  return (
    <div className="app">
      <Navbar />
      <HoverPic />
      <Listen />
      <Gallery/>
      {/* <Watch /> */}
      <ContactPage/>
      <Footer />
    </div>
  );
}

export default App;
