import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";
import AeLogo from "../../assets/logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <MDBFooter  className="font-small footer color">
      <div className="footer-copyright text-center py-2">
        <MDBContainer fluid>
          <img className="ae-logo-footer" src={AeLogo} alt=""></img>
          <div className="copy">
            &copy; {new Date().getFullYear()} ALL
            RIGHTS RESERVED.
          </div>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
