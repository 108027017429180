import React, { Component } from "react";
import Coverflow from 'react-coverflow';
import StyleRoot from 'react-coverflow';


import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import "./gallery.css";

import A from "../../assets/gallery/1.jpg";
import B from "../../assets/gallery/2.jpg";
import C from "../../assets/gallery/3.jpg";
import D from "../../assets/gallery/4.jpg";
import E from "../../assets/gallery/5.jpg";
import F from "../../assets/gallery/6.jpg";
// import G from "../../assets/gallery/7.jpg";


const fn = function () {
  /* do your action */
}

class Gallery extends Component {
  
  render() {
    return (
      <div className='gal'>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12">
          <StyleRoot>
          <Coverflow 
    displayQuantityOfSide={2}
    navigation={false}
    enableScroll={false}
    clickable={true}
    active={2}
    enableHeading={false}
    media={{
      '@media (max-width: 900px)': {
        width: '600px',
        height: '600px'
      },
      '@media (min-width: 900px)': {
        width: '960px',
        height: '600px'
      }
    }}
    
  >
    <div
      // onClick={() => fn()}
      onKeyDown={() => fn()}
      role="menuitem"
      tabIndex="0"
    >
   
    </div>
    {/* <img src={A} alt='' data-action=""/> */}

    <img src={A} alt='' />
    <img src={B} alt=''/>
    <img src={C} alt=''/>
    <img src={D} alt=''/>
    <img src={E} alt=''/>
    <img src={F} alt=''/>
    {/* <img src={G} alt=''/> */}



  </Coverflow>
  </StyleRoot>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      </div>
    );
  }
}

export default Gallery;
