import React from "react";
import { MDBNavbar, MDBIcon } from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
// import geezylogo from "../../assets/geezylogo.png";
import Logo from "../../assets/logo.png";

import "./navbar.css";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  render() {
  
    return (
      <div>
        <Router>
          <header>
            <MDBNavbar
              className="navbar color"
              id='border'
              dark
              scrolling
              fixed="top"
            >
              <img className="logo" src={Logo} alt=""></img>

              {/* <img className="geezy-logo" src={geezylogo} alt=""></img> */}


              <div className='links'>


                <a
                  href="https://m.facebook.com/jtglistenin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon id="fb" fab icon="facebook-f" />
                </a>

                <a
                  href="https://mobile.twitter.com/jtglistenin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon id="twitter" fab icon="twitter" />
                </a>

                <a
                  href="https://www.instagram.com/jtglistenin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon id="insta" fab icon="instagram" />
                </a>
              </div>
            </MDBNavbar>
          </header>
        </Router>
      </div>
    );
  }
}

export default Navbar;
