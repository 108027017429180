import React from "react";
import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import "./contact.css";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    };
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST", 
      url:"api/send", 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success'){
        alert("Message Sent."); 
        this.resetForm()
      }else if(response.data.status === 'fail'){
        alert("Message failed to send.")
      }
    })
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    });
  }

  render() {
    return (
      <section className="contact-section my-5 contact" >
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        <MDBCard>
          <MDBRow>
            <MDBCol lg="8">
              <MDBCardBody className="form"  >
                <h3 className="mt-4">
                  <MDBIcon icon="envelope" className="pr-2" />
                  Write to us:
                </h3>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-name"
                        label="Your name"
                        value={this.state.name}
                        onChange={this.onNameChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-email"
                        label="Your email"
                        value={this.state.email}
                        onChange={this.onEmailChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-phone"
                        label="Your phone"
                        value={this.state.phone}
                        onChange={this.onPhoneChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-company"
                        label="Your company"
                        value={this.state.company}
                        onChange={this.onCompanyChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="textarea"
                        id="form-contact-message"
                        label="Your message"
                        value={this.state.message}
                        onChange={this.onMessageChange.bind(this)}
                      />
                      <MDBBtn rounded color="black" type='submit'>
                        <MDBIcon icon="paper-plane" />
                      </MDBBtn>

                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
            <MDBCol lg="4">
              <MDBCardBody className="contact h-100 black-text">
                <h3 className="my-4 pb-2 text-center ">CONTACT</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                  <li>
                    <p>
                      <MDBIcon icon="envelope" className="pr-2" />
                      jtglistenin00@gmail.com
                    </p>
                    
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
        </form>
      </section>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }
  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  onPhoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  onCompanyChange(event) {
    this.setState({ company: event.target.value });
  }
  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
}

export default ContactPage;
