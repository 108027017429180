// import image from "../../assets/album-cover.jpg";
// import bythecover from "../../assets/bythecoveredit.jpg";
import AlbumPic from "../album-art/album-art";
import Bio from "../bio/bio"

import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import "./listen.css";


class AlbumCover extends React.Component {
  btnClick() {
    window.open("https://m.youtube.com/channel/UCsrV9z9iJaGGXcvEQSwCVQg");
  }
  render() {
    return (
      <div className="imgcont color">
        <MDBRow>
          <MDBCol md="6">
            <AlbumPic />
            <div id="top">
            <button 
              className="listen-button"
              onClick={this.btnClick.bind(this)}
            >
              LISTEN NOW
            </button>
            </div>
          </MDBCol>

          <MDBCol md="1">
        </MDBCol>

        <MDBCol md="4">
          <Bio/>
        </MDBCol>

        </MDBRow>
      </div>
    );
  }
}

export default AlbumCover;
